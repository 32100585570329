<template>
    <Layout>
        <h4>Reference Number : {{ details.ref_no }}</h4>
        <p>Amendment Type : {{ details.amendment_type }}</p>
        <b-overlay :show="show" rounded="sm">
            <div class="row">
                <div class="col-lg-7">
                    <b-tabs
                        active-nav-item-class="font-weight-bold text-uppercase text-primary"
                        active-tab-class="font-weight-bold text-success"
                        content-class="mt-3"
                    >
                        <b-tab title="Request Details" active>
                            <div>
                                <p>
                                    <span class="title"
                                        >Buyer Mortgage Bank Name :</span
                                    >
                                    &nbsp;&nbsp;{{
                                        details.buyer_mortgage_bank_name
                                    }}
                                </p>

                                <p>
                                    <span class="title"> Created At :</span>
                                    &nbsp;&nbsp;{{ details.created_at }}
                                </p>
                                <div
                                    v-for="status in requestStatus"
                                    :key="status.id"
                                >
                                    <p
                                        v-if="status.id === details.status"
                                        :class="status.text"
                                    >
                                        <span class="title"> Status :</span>
                                        &nbsp;&nbsp;
                                        {{ status.text }}
                                    </p>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Owners Information">
                            <div>
                                <p>
                                    <span class="title">Unit Number :</span>
                                    &nbsp;&nbsp;{{ details.unit_no }}
                                </p>
                                <p>
                                    <span class="title"> Name :</span>
                                    &nbsp;&nbsp;{{ details.user_name }}
                                </p>
                                <p>
                                    <span class="title"> Email :</span>
                                    &nbsp;&nbsp;{{ details.email }}
                                </p>
                                <p>
                                    <span class="title"> Nationality :</span>
                                    &nbsp;&nbsp;{{ details.nationality }}
                                </p>
                                <p>
                                    <span class="title"> PO Box Number :</span>
                                    &nbsp;&nbsp;{{ details.po_box }}
                                </p>
                                <p>
                                    <span class="title">
                                        Passport Number :</span
                                    >
                                    &nbsp;&nbsp;{{ details.passport_no }}
                                </p>
                                <p>
                                    <span class="title"> Mobile :</span>
                                    &nbsp;&nbsp;{{ details.mobile }}
                                </p>
                                <p>
                                    <span class="title"> Phone :</span>
                                    &nbsp;&nbsp;{{ details.mobile }}
                                </p>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <p>
                                            Passport&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(
                                                        details.passport
                                                    )
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Emirates Id&nbsp;&nbsp;:&nbsp;&nbsp;

                                            <b-button
                                                @click="
                                                    viewDocument(details.eid)
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Visa Copy&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(details.visa)
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab title="Documents">
                            <div class="documents">
                                <p>
                                    MOU&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="viewDocument(details.mou)"
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>

                                <p>
                                    Third Party Liability
                                    Insurance&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="
                                            viewDocument(
                                                details.third_party_liability_insurance
                                            )
                                        "
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                                <p>
                                    Owner Title Deed&nbsp;&nbsp;:&nbsp;&nbsp;
                                    <b-button
                                        @click="
                                            viewDocument(details.title_deed)
                                        "
                                        variant="primary"
                                    >
                                        View
                                    </b-button>
                                </p>
                            </div>
                        </b-tab>

                        <b-tab title="Buyers Information">
                            <div
                                v-for="(buyer, index) in details.buyers"
                                :key="buyer.id"
                            >
                                <h5>Buyer {{ index + 1 }}</h5>
                                <hr />
                                <p>
                                    <span class="title">Buyer Name :</span>
                                    &nbsp;&nbsp;{{ buyer.buyer_name }}
                                </p>
                                <p>
                                    <span class="title"> Buyer City :</span>
                                    &nbsp;&nbsp;{{ buyer.buyer_city }}
                                </p>
                                <p>
                                    <span class="title"> Buyer Country :</span>
                                    &nbsp;&nbsp;{{ buyer.buyer_country_name }}
                                </p>
                                <p>
                                    <span class="title"> Buyer PO Box :</span>
                                    &nbsp;&nbsp;{{ buyer.buyer_po_box }}
                                </p>
                                <p>
                                    <span class="title">
                                        Buyer Phone Number :</span
                                    >
                                    &nbsp;&nbsp;{{ buyer.buyer_phone_no }}
                                </p>
                                <p>
                                    <span class="title">
                                        Buyer Mobile Number :</span
                                    >
                                    &nbsp;&nbsp;{{ buyer.buyer_mobile_no }}
                                </p>
                                <p>
                                    <span class="title">
                                        Buyer Fax Number :</span
                                    >
                                    &nbsp;&nbsp;{{ buyer.buyer_fax_no }}
                                </p>
                                <p>
                                    <span class="title"> Buyer Email:</span>
                                    &nbsp;&nbsp;{{ buyer.buyer_email }}
                                </p>
                                <p>
                                    <span class="title">
                                        Buyer Physical Address:</span
                                    >
                                    &nbsp;&nbsp;{{
                                        buyer.buyer_physical_address
                                    }}
                                </p>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <p>
                                            Buyer
                                            Passport&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(
                                                        buyer.buyer_passport
                                                    )
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Buyer Emirates
                                            Id&nbsp;&nbsp;:&nbsp;&nbsp;

                                            <b-button
                                                @click="
                                                    viewDocument(
                                                        buyer.buyer_eid
                                                    )
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                    <div class="col-lg-4">
                                        <p>
                                            Buyer Visa
                                            Copy&nbsp;&nbsp;:&nbsp;&nbsp;
                                            <b-button
                                                @click="
                                                    viewDocument(
                                                        buyer.buyer_visa
                                                    )
                                                "
                                                variant="primary"
                                            >
                                                View
                                            </b-button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div class="col-lg-5">
                    <auditManagementApproval
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />
                    <br />
                    <addComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <addRejectComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <workFlowDiagram
                        :workAssigned="workAssigned"
                        :details="details"
                    />
                </div>
            </div>
            <hr />

            <accountsApproval :details="details" :requestType="requestType" />
            <!-- <div v-if="userRole != 'Owner' && userRole != 'Tenant'">
                <h2>Accounts Approval</h2>

                <div v-if="details.service_fees_payment_mode">
                    <p>Payment Mode</p>
                    <p
                        v-if="
                            details.service_fees_payment_mode == 1 &&
                            (userRole == 'Accounts' ||
                                userRole == 'Accounts Executive')
                        "
                    >
                        {{ details.merchant_reference }}
                    </p>
                    <p v-if="details.service_fees_payment_mode == 1">Online</p>
                    <p v-if="details.service_fees_payment_mode == 2">Cash</p>
                    <p v-if="details.service_fees_payment_mode == 3">Cheque</p>
                </div>

                <div v-if="details.urgent_fees_payment_mode">
                    <p>Urgent Fees:</p>
                    <p
                        v-if="
                            details.urgent_fees_payment_mode == 1 &&
                            (userRole == 'Accounts' ||
                                userRole == 'Accounts Executive')
                        "
                    >
                        {{ details.merchant_reference }}
                    </p>
                    <p v-if="details.urgent_fees_payment_mode == 1">Online</p>
                    <p v-if="details.urgent_fees_payment_mode == 2">Cash</p>
                    <p v-if="details.urgent_fees_payment_mode == 3">Cheque</p>
                </div>

                <div v-if="details.security_deposit_payment_mode">
                    <p>Security Deposit</p>
                    <p v-if="details.security_deposit_payment_mode == 2">
                        Cash
                    </p>
                    <p v-if="details.security_deposit_payment_mode == 3">
                        Cheque
                    </p>
                </div>
                <span v-if="details.payment_approved_by">
                    <span
                        ><strong style="color: green">Approved</strong> on
                        {{ details.payment_approved_time }}</span
                    >
                </span>
                <span
                    v-if="
                        !details.payment_approved_by && userRole != 'Accounts'
                    "
                >
                    <p>Pending</p>
                </span>
                <span
                    v-if="
                        (userRole == 'Accounts' ||
                            userRole == 'Accounts Executive') &&
                        !details.payment_approved_by &&
                        !details.service_fees_payment_mode &&
                        !details.urgent_fees_payment_mode &&
                        !details.security_deposit_payment_mode
                    "
                >
                    <p>Payment Pending</p>
                </span>
            </div> -->
            <br />

            <updateRequestStatus
                :details="details"
                :requestType="requestType"
            />
            <br />

            <div v-if="userRole != 'Owner' && userRole != 'Tenant'">
                <h2>Print View</h2>

                <b-button variant="primary" @click="printView(details.id)"
                    >Print
                </b-button>
            </div>
        </b-overlay>
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Layout from "../../layouts/main.vue";
import { BASE_URL, REQUEST_STATUS } from "../../common";
import axios from "axios";
import unitMixin from "../../../mixins/unitMixin";
import addComment from "../requestFlow/addComments.vue";
import addRejectComment from "../requestFlow/addRejectionComments.vue";
import workFlowDiagram from "../requestFlow/workFlow.vue";
import auditManagementApproval from "../requestFlow/audit&ManagementApproval.vue";
import updateRequestStatus from "../requestFlow/statusUpdate.vue";
import accountsApproval from "../requestFlow/accountsApproval.vue";
export default {
    mixins: [unitMixin],
    components: {
        Layout,
        addComment,
        addRejectComment,
        workFlowDiagram,
        auditManagementApproval,
        updateRequestStatus,
        accountsApproval,
        // VueHtml2pdf,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
            console.log(this.$route.params.id);
            this.getDetails(this.$route.params.id);
        }
    },
    data() {
        return {
            requestType: null,
            workAssigned: [],
            userRole: null,
            requestStatus: REQUEST_STATUS,
            test: "admin",
            show: true,
            details: {},
        };
    },
    methods: {
        printView(id) {
            console.log(id);
            if (this.details.amendment_type === "GIFT") {
                this.$router.push({
                    path: `/amendment/gift/${id}`,
                });
            } else {
                this.$router.push({
                    path: `/amendment/sale/${id}`,
                });
            }
            // this.$router.push({
            //     path: `/amendment/print/${id}`,
            // });
        },
        refetchItem() {
            this.getDetails(this.$route.params.id);
        },
        getDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}amendments/${id}?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.show = false;
                        this.details = res.data.response;
                        this.requestType = res.data.request_type;
                        this.workAssigned = res.data.workassigned;
                    });
            }
        },
    },
};
</script>
<style scoped>
.documents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.title {
    font-weight: bolder;
}
p {
    color: black;
}

.Pending {
    color: #ff635c;
}
.Started {
    color: #ffc71a;
}
.Completed {
    color: #19b699;
}
.Closed {
    color: #777;
}
#awaiting {
    color: #1b3863;
}
</style>
